import { AsyncServerResponse } from '../Models/AsyncServerResponse';
import { PRFQ } from '../Models/PRFQ';
import { IHttpService, ISAPService } from './Interfaces/Interfaces';

export class SAPService implements ISAPService {
    httpService : IHttpService;

    constructor(httpService : IHttpService)
    {
        this.httpService = httpService;
    }

    getPrfq(guid: string) : AsyncServerResponse<PRFQ> {
        return this.httpService.httpGet(`prfq/${guid}`);
    }

    submitPrfq(prfq : PRFQ) : AsyncServerResponse {
        return this.httpService.httpPost(`prfq`, prfq);
    }
}