import { AsyncServerResponse } from "../Models/AsyncServerResponse";
import { ServerResponse } from "../Models/ServerResponse";
import { Settings } from "../Settings/Settings";
import { IHttpService } from "./Interfaces/Interfaces";

export class HttpService implements IHttpService {
    private token : string | null = null;

    httpGet<T>(relativeUrl : string) : AsyncServerResponse<T> {
        return this.doHttp(relativeUrl);
    }

    httpPost<T>(relativeUrl : string, data : any | undefined) : AsyncServerResponse<T> {
        const requestOptions : RequestInit = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: data ? JSON.stringify(data) : null
        };

        return this.doHttp(relativeUrl, data, requestOptions);
    }

    private doHttp<T>(relativeUrl : string, data? : any, requestOptions? : RequestInit) : AsyncServerResponse<T> {
        return fetch(Settings.API_ROOT_URL + relativeUrl, requestOptions)
            .then(x => {
                if (x.ok) {
                    return x.json()
                }

                let response = new ServerResponse<T>();
                response.IsSuccess = false;

                return response;  
            })
            .catch(x => {
                return x.json();
            });
    }
}