import { Component } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import './App.css';
import { PRFQ } from './Models/PRFQ';
import { HttpService } from './Services/HttpService';
import { IHttpService, ISAPService } from './Services/Interfaces/Interfaces';
import { SAPService } from './Services/SAPService';
import { DataType } from './Enums/DataType';
import Moment from 'moment';
import { Checkbox, FormControlLabel } from '@mui/material';

export class AppState {
  prfq : PRFQ | null = null;
  guid = '';
  hasError = false;
  isLoading = true;
  isAcknowledged = false;
}

export class App extends Component<{}, AppState> {
  httpService : IHttpService = new HttpService();
  sapService : ISAPService = new SAPService(this.httpService);

  constructor(props : {}) {
    super(props);

    let state = new AppState();
    state.guid = this.getPrfqGuidFromQueryString();

    this.state = state;
  }

  componentDidMount() {
    if (!this.state.guid) {
      this.setState({hasError: true});
    }

    this.sapService.getPrfq(this.state.guid)
      .then(x => {
        if (!x.Data) {
          this.setState({hasError: true});
          return;
        }

        if (x.Data.LineItems) {
          x.Data.LineItems.forEach(y => {
            y.DeliveryDate = this.formatDate(y.DeliveryDate || '');
            y.Price = y.Price ? (+(y?.Price ?? 0)).toFixed(2) : '';
          });
        }
        this.setState({
          prfq: x.Data,
          isLoading: false
        });
      })
      .catch((ex) => {
        console.error(ex);
        this.setState({
          hasError: true,
          isLoading: false
        });
      });
  }

  render() {
    Moment.locale('en');
    const prfq = this.state.prfq;
    const items = prfq?.LineItems || [];
    const checkLabel = <span>I acknowledge the <a target="_blank" href="https://www.cristek.com/resources/QVS-0164-0101_revZ.pdf">Cristek Quality Notes</a> as applicable to this Supplier quotation.</span>;
    const showDrawingRev = prfq?.LineItems?.some(x => x.PartDwgRev) ?? false;

    return (
      <div className="App">
        { this.state.isLoading && !this.state.hasError &&
          <div className="spinner"></div>
        }
        <Container>
          <div className="spacer"></div>
          <div>
            <img className="logo" alt="" src="/Images/logo.png"></img>
          </div>     
          <div className="spacer"></div>
        </Container> 
        { prfq && !this.state.hasError && !prfq.IsProcessing &&
          <div>
            <div className="general-info">
              <Container>
                <Row>
                  <Col>
                    <div>
                      <label>Reference</label>
                      <input disabled={true} readOnly={true} value={prfq?.QuoteNumber || ''}></input>
                    </div>
                    <div>
                      <label>Buyer</label>
                      <input disabled={true} readOnly={true} value={prfq?.BuyerName || ''}></input>
                    </div>
                    <div>
                      <label>Ship To</label>
                      <textarea disabled={true} readOnly={true} value={this.removeEmptyLines(prfq?.ShipToAddress || '')}></textarea>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label>Contact Person</label>
                      <input disabled={true} readOnly={true} value={prfq?.ContactPerson || ''}></input>
                    </div>
                    <div>
                      <label>Valid Until</label>
                      <input disabled={true} readOnly={true} value={this.formatDate(prfq?.RequiredDate || '')}></input>
                    </div>
                    <div>
                      <label>Bill To</label>
                      <textarea disabled={true} readOnly={true} value={this.removeEmptyLines(prfq?.BillToAddress || '')}></textarea>
                    </div>
                  </Col>
                  <Col>
                    <div>
                      <label>Company Name</label>
                      <input disabled={true} readOnly={true} value={prfq?.CompanyName || ''}></input>
                    </div>
                    <div>
                      <label>Requested Date</label>
                      <input disabled={true} readOnly={true} value={this.formatDate(prfq?.RequestedDate || '')}></input>
                    </div>
                    <div>
                      <label>Vendor Ref No.</label>
                      <input value={prfq?.VendorRefNo || ''} onChange={(e) => this.setState({prfq: {...prfq, VendorRefNo: e.target.value }})}></input>
                    </div>
                    <div className="acknowledgement">
                      <FormControlLabel control={<Checkbox />} label={checkLabel} value={this.state.isAcknowledged} onChange={() => this.setState({isAcknowledged: true})} />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="item-table">
              <Container>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th style={{width:'10%'}}>No.</th>
                      <th>Name</th>
                      { showDrawingRev &&
                        <th style={{width:'7%'}}>Drawing Revision</th>
                      }
                      <th style={{width:'5%'}}>UoM</th>
                      <th style={{width:'5%'}}>Units</th>
                      <th style={{width:'7%'}}>Required Qty</th>
                      <th style={{width:'7%'}}>Quoted Qty</th>
                      <th style={{width:'7%', minWidth: '75px'}}>Price</th>
                      <th style={{width:'7%'}}>Lead Time (Weeks)</th>
                      <th style={{width:'20%', minWidth: '150px'}}>MOQ / Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                    { items.map((x, i) => 
                      <tr key={i}>
                        <td className="nowrap">{x.ItemCode}</td>
                        <td>{x.ItemDescription}</td>
                        { showDrawingRev &&
                          <td>{x.PartDwgRev}</td>
                        }
                        <td>{x.UnitOfMeasure}</td>
                        <td>{x.MeasureUnit}</td>
                        <td>{x.RequiredQty}</td>
                        <td><input value={x.QuotedQty || ''} name="QuotedQty" onChange={e => this.updateLineItem(i, e, DataType.Number)}></input></td>
                        <td>
                          <div className="nowrap">{x.Currency || '$ '}&nbsp;<input value={x.Price || ''} name="Price" onChange={e => this.updateLineItem(i, e, DataType.Currency)} style={{width:'85%'}}></input></div></td>
                        <td><input value={x.LeadTime || ''} name="LeadTime" onChange={e => this.updateLineItem(i, e, DataType.Number)}></input></td>
                        <td><input value={x.LineNotes || ''} name="LineNotes" onChange={e => this.updateLineItem(i, e, DataType.Text)}></input></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Container>
            </div>
            <div>
              <Container className="footer-container">
                <Row>
                  <Col>
                    <div className="footer-info">
                        For any attachment requirements, please send via email.<br />
                    </div>
                  </Col>
                  <Col xs={3}>
                      <div className="buttons">
                        <Button onClick={() => this.onCancelClick()} variant="secondary">Cancel</Button>
                        <Button onClick={() => this.onSubmitClick()}>Submit</Button>
                      </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>    
        }
        { this.state.hasError &&
          <Container>
            <div className="error">An error has occured. Please contact Cristek for further assistance.</div>
          </Container>
        }
        { prfq?.IsProcessing &&
          <Container>
            <div className="processing">Thank you! Your request is being processed.</div>
          </Container>
        }
          <Container className="copyright">
            &copy;CRISTEK {new Date().getFullYear()}
          </Container>
        </div>
    );
  }

  private onSubmitClick() {
    if (!this.validate())
    {
      alert('Please enter quoted quantities and prices for all line items.');
      return;
    }

    if (!this.state.isAcknowledged) {
      alert('Please acknowledge the Cristek Quality Notes.');
      return;
    }

    if (!window.confirm('Are you sure you want to submit this quote?')) {
      return;
    }

    this.setState({
      isLoading: true
    });

    if (!this.state.prfq) {
      return;
    }

    this.sapService.submitPrfq(this.state.prfq)
      .then((x) => {
        this.setState({
          isLoading: false,
          hasError: !x.IsSuccess
        });
        if (x.IsSuccess) {
          this.submitSuccess();
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          hasError: true
        });
      });
  }

  private submitSuccess() {
    this.setState({
      prfq: {
        ...this.state.prfq,
        IsProcessing: true
      }
    });
  }

  private onCancelClick() {
    if (!window.confirm('Are you sure you want to cancel?')) {
      return;
    }
    
    window.location.reload();
  }

  private validate() {
    if (!this.state.prfq || !this.state.prfq.LineItems) {
      return false;
    }

    return !this.state.prfq.LineItems.some(x => !x.QuotedQty || !x.Price);
  }

  private updateLineItem(idx : number, evt : React.ChangeEvent<HTMLInputElement>, dataType : DataType) {
    let prfq = this.state.prfq;

    if (prfq == null || !prfq.LineItems) {
      return;
    }

    let val = evt.target.value;

    if (!this.isInputValid(val, dataType)) {
      return;
    }

    prfq.LineItems[idx] = {
      ...prfq.LineItems[idx],
      [evt.target.name]: val
    };

    this.setState({prfq});
  }

  private isInputValid(input : string, dataType : DataType) {
    if (input === '' || dataType === DataType.Text) {
      return true;
    }

    if (dataType === DataType.Number) {
      return input.match(/^\d+$/g);
    }
    if (dataType === DataType.Decimal) {
      return input.match(/^\d*\.?\d*$/g);
    }
    if (dataType === DataType.Date) {
      return true;
    }
    if (dataType === DataType.Currency) {
      return input.match(/^\d*\.?\d{0,2}$/g);
    }
  }

  private getPrfqGuidFromQueryString() {
    return new URLSearchParams(window.location.search).get('q') || '';
  }

  private removeEmptyLines(input : string) {
    input = input.replace('\r\r', '\r');
    input = input.replace('\n\n', '\n');
    input = input.replace('  ', ' ');
    return input;
  }

  private formatDate(input : string) {
    if (!input) {
      return input;
    }

    return Moment(input).format('MM/DD/YYYY')
  }

  // private formatDescription(input : string) {
  //   return input.replace('-', '&#x2011;');
  // }

  private formatPrice(input : number | string) {
    if (!input) {
      return '';
    }

    if (!+input) {
      return input;
    }

    return `$${(+input).toFixed(2)}`;
  }
}